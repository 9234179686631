import { Logout } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { AppBreadcrumb } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signOut } from 'reducers/profile';
import axios from 'axios';
import { API_URL } from 'env';

const Header = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector(profileSelector);

  const onSignOut = async () => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: API_URL + '/api/v1/logout',
        data: {
          access_token: accessToken,
        },
      });
      if (response.status === 200) {
        dispatch(signOut());
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <AppBar position='sticky' color='inherit' elevation={1}>
        <Toolbar>
          <AppBreadcrumb />
          <div className='flex-1 flex items-center justify-between'>
            <div className='text-xl font-bold'>Result</div>
            <IconButton className='mr-3' onClick={onSignOut}>
              <Logout />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
