import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Typography } from '@mui/material';
import {Spinner, TableEmpty} from 'components';
import {useQuery} from 'react-query';
import {systemService} from 'services';
import {useParams} from 'react-router-dom';
import styled from '@emotion/styled';
import { openNotification } from 'reducers/notification';
import {store} from "../../reducers";
import {AxiosError} from "axios";
import { StatusCodes } from "../../services/statusCodes";

const StyledTable = styled(Table)`
  * {
    border: 1px solid black;
  }

  .MuiTableCell-head {
    background: gray !important;
  }
`;

const StyledDetails = styled.details`
  summary::-webkit-details-marker {
    display: none
  }
`

const Home = () => {
  const params = useParams();
  const resultId = ((params.resultId !== ":resultId" ? params.resultId : null) || localStorage.getItem("result_id")) as string;
  window.history.pushState('resultId', '', `/results/${resultId}`);
  const {data, isFetching, error} = useQuery(
    ['systemService.fetchRecords', resultId],
    () => systemService.fetchRecords({resultId}),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  if (error) {
    const data = (error as AxiosError).response?.data
    const status = (error as AxiosError).response?.status || 400;
    store.dispatch(openNotification({ message:  (data as Error).message, variant: 'error' }));
    return <Grid container justifyContent="center" flexDirection={"column"} alignItems={"center"} className="mt-72">
      <Grid alignItems={"left"}>
        <Grid item>
          <Typography fontSize="32px">{status} {StatusCodes[status]}</Typography>
        </Grid>
        <Grid item>
          <Typography fontSize="20px" color={"gray"}>{(data as Error).message}</Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  return (
    <>
      {data?.metadata && <Grid container>
        <Grid item sm={12}>
          <div className='text-xl font-bold mt-2'>Metadata</div>
          <Divider className='mb-5'/>
          <ul className='list-disc list-inside mt-3'>
            <li><b>Author: </b> <a className='underline bg-yellow-200' target='_blank' href={`https://github.com/${data?.metadata.author.github}`}>@{data?.metadata.author.github}</a></li>
            <li><b>Repository: </b> <a className='underline' target='_blank' href={`https://github.com/${data?.metadata.repository}`}>{data?.metadata.repository}</a></li>
            <li><b>Commit: </b> <a className='underline' target='_blank' href={`https://github.com/${data?.metadata.repository}/commit/${data?.metadata.commit}`}>{data?.metadata.commit.substring(0,7)}</a></li>
          </ul>
          <StyledDetails>
            <summary className='list-disc list-inside'><b>Query: </b> details </summary>
            <pre>
                <code className='bg-gray-300'>{data?.metadata.query}</code>
            </pre>
          </StyledDetails>
        </Grid>
      </Grid>}
      <Grid container>
        <Grid item sm={12}>
          <div className='text-xl font-bold mt-2'>Records</div>
          <Divider className='mb-5'/>
          <TableContainer component={Paper}>
            <Spinner loading={isFetching}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {data?.columns.map((column, index) => (
                      <TableCell key={index}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.records.map((item, index) => (
                    <TableRow key={index}>
                      {data?.columns.map((column, index) => (
                        <TableCell key={index} className='text-center'>
                          {item[`${column}`]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableEmpty data={data?.records}/>
                </TableBody>
              </StyledTable>
            </Spinner>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
